export function map() {
    const mapMain = document.getElementById('map');
    if (mapMain) {
        ymaps.ready(init);
        var myMap;

        const coordinates = mapMain.getAttribute('data-coords');

        function init() {
            myMap = new ymaps.Map("map", {
                center: [Number(coordinates.split(',')[0]), Number(coordinates.split(',')[1])], 
                zoom: 9,
                controls: []
            });
            setTimeout(() => {
                let placemark = new ymaps.Placemark([Number(coordinates.split(',')[0]), Number(coordinates.split(',')[1])], {}, {
                    iconLayout: "default#image",
                    iconImageHref: '/static/assets/img/map-pin.svg',
                    iconImageSize: [56, 56],
                    iconImageOffset: [-28, -56],
                })

                myMap.geoObjects.add(placemark);
            }, 50);
        }
    };
}