export function slider() {
    const materialMobile = new Swiper('.main-articles-slider', {
        slidesPerView: 1.195,
        slidesOffsetBefore: 32,
        slidesOffsetAfter: 32,
        spaceBetween: 32,
        breakpoints: {
            768: {
                slidesPerView: 2.39,
                slidesOffsetBefore: getRem() * 10,
                slidesOffsetAfter: getRem() * 10,
            },
            280: {
                slidesOffsetBefore: 9,
                slidesOffsetAfter: 9,
                spaceBetween: 9,
            }
        },
        mousewheel: {
            forceToAxis: true
        }
    })
    const about = new Swiper('.about-slider', {
        slidesPerView: 1.06,
        slidesOffsetBefore: 12,
        slidesOffsetAfter: 164,
        spaceBetween: 12,
        breakpoints: {
            768: {
                slidesPerView: 2.427,
                slidesOffsetBefore: getRem() * 10,
                slidesOffsetAfter: getRem() * 35.5,
            }
        },
        mousewheel: {
            forceToAxis: true
        },
        navigation: {
            nextEl: ".about-button-next",
            prevEl: ".about-button-prev",
        },
    })
    const aboutSecond = new Swiper('.about-slider-second', {
        slidesPerView: 1.06,
        slidesOffsetBefore: 164,
        slidesOffsetAfter: 12,
        spaceBetween: 12,
        breakpoints: {
            768: {
                slidesPerView: 2.427,
                slidesOffsetBefore: getRem() * 35.5,
                slidesOffsetAfter: getRem() * 10,
            }
        },
        mousewheel: {
            forceToAxis: true
        },
        navigation: {
            nextEl: ".about-button-next",
            prevEl: ".about-button-prev",
        },

    })
    const aboutTeam = new Swiper('.about-team-slider', {
        slidesPerView: 1.52,
        slidesOffsetBefore: 12,
        slidesOffsetAfter: 12,
        spaceBetween: 12,
        breakpoints: {
            768: {
                slidesPerView: 4.75,
                slidesOffsetBefore: getRem() * 10,
                slidesOffsetAfter: getRem() * 10,
                spaceBetween: getRem() * 2,
            }
        },
        mousewheel: {
            forceToAxis: true
        }
    })
    const whyUs = new Swiper('.why-us-slider', {
        slidesPerView: 1.37,
        slidesOffsetBefore: 12,
        slidesOffsetAfter: 12,
        spaceBetween: 12,
        breakpoints: {
            768: {
                slidesPerView: 4.75,
                slidesOffsetBefore: getRem() * 10,
                slidesOffsetAfter: getRem() * 10,
                spaceBetween: getRem() * 2,
            }
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const benefit = new Swiper('.benefit-slider', {
        slidesPerView: 1.4,
        spaceBetween: 12,
        breakpoints: {
            768: {
                slidesPerView: 4.3,
                spaceBetween: getRem() * 2,
            }
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const review = new Swiper('.review-slider', {
        slidesPerView: 1.2,
        spaceBetween: 12,
        breakpoints: {
            768: {
                slidesPerView: 2.2,
                spaceBetween: getRem() * 2,
            }
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const promotions = new Swiper('.main-hero-swiper', {
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    about.controller.control = aboutSecond;
    aboutSecond.controller.control = about;
}

export function getRem() {
    var element = document.querySelector("html");
    var fontSize = window.getComputedStyle(element).fontSize;
    var numericFontSize = parseInt(fontSize, 10);
    return fontSize.slice(0, -2)
}


// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { slider } from "./путь/к/файлу/slider.js";

// Активация: slider();