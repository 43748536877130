export function mobileMenu() {
    const menu = document.querySelector('.header-section__mobile-menu');
    const inset = document.querySelector('.__inset');
    if(menu) {
        const openMenuBtn = document.querySelector('.open-mobile-menu');
        openMenuBtn.addEventListener('click',()=>{
            if(!openMenuBtn.classList.contains('active')) {
                openMenuBtn.classList.add('active');
                insetActive(inset);
                openMenu(menu);
            } else {
                openMenuBtn.classList.remove('active');
                insetInactive(inset);
                closeMenu(menu);
            }
        });
    };
    if(inset) {
        inset.addEventListener('click',()=>{
            closeMenu(menu);
        });
    };
}
const header = document.querySelector('header');
const main = document.querySelector('main');

function insetActive(inset) {
    inset.classList.add('show');
    document.documentElement.style.overflow = 'hidden';
}
function insetInactive(inset) {
    inset.classList.remove('show');
    document.documentElement.style.overflow = null;
}
function openMenu(menu) {
    menu.style.maxHeight = menu.scrollHeight * 2 + "px";
    header.style.position = 'fixed';
    header.style.top = 0;
    main.style.paddingTop = '8rem';
}
function closeMenu(menu) {
    menu.style.maxHeight = null;
    setTimeout(() => {
        main.style.paddingTop = null;
        header.style.top = null;
        header.style.position = null;
    }, 500);
}