import '../styles/main.scss';

import { accordion } from "./modules/base/accordion.js";
import { header } from "./modules/header.js";
import { mobileMenu } from "./modules/mobileMenu.js";

import { inputs } from "./modules/inputs.js";
import { select } from "./modules/base/select.js";
import { anchor } from "./modules/base/anchor.js";
import { modals } from "./modules/base/modals.js";
import { validation } from "./modules/validation.js";
import { slider } from "./modules/slider.js";

import { serviceModal } from "./modules/serviceModal.js";
import { map } from "./modules/map.js";
import { cookieAcceptation } from "./modules/cookieAcceptation.js";
import { topBtn } from './modules/top-btn.js';
import { seeMore } from './modules/seeMore.js';

accordion()
header()
mobileMenu()

select()
inputs()
anchor()
modals()
validation()
slider()

serviceModal()
map()
cookieAcceptation()
topBtn()
seeMore()