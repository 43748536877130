export function seeMore() {
    const reviews = document.querySelectorAll('.review-card');

    if (reviews) {
        reviews.forEach(item => {
            let reviewText = item.querySelector('.review-card__text span');
            let btnOpen = item.querySelector('.see-more-btn.open');
            let btnClose = item.querySelector('.see-more-btn.close');

            if (btnOpen && btnClose && reviewText) {
                btnOpen.addEventListener('click', function() {
                    btnOpen.classList.remove('active');
                    btnClose.classList.add('active');
                    reviewText.style.display = 'flex';
                });

                btnClose.addEventListener('click', function() {
                    btnClose.classList.remove('active');
                    btnOpen.classList.add('active');
                    reviewText.style.display = '-webkit-box';
                });
            }
        });
    }
}
