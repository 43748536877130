import { z } from "../libraries/zod.js"
import { findParentWithClass, sendData } from "./utils.js";


function getTextInsideSquareBrackets(text) {
    const startIndex = text.indexOf('['); // Находим индекс первой открывающей скобки
    const endIndex = text.lastIndexOf(']'); // Находим индекс последней закрывающей скобки
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) { // Проверяем, что скобки найдены и первая скобка находится перед последней
        return text.substring(startIndex + 1, endIndex); // Возвращаем текст между первой и последней скобками
    } else {
        return ''; // Если скобки не найдены или первая скобка находится после последней, возвращаем пустую строку
    }
}

const schemaCallBack = z.object({
    mail: z.string(),
    phone: z.string()
        .min(18),
    full_name: z.string()
        .min(1),
});

export function validation() {
    const feedback = document.getElementById('feedback-form');
    if(feedback) {
        formValidate(
            feedback, 
            schemaCallBack, 
            (body) => {
                sendData(body,'/api/feedback-request/').then(res=>{
                    window.location.href = '/success/';
                }).catch(error=>{
                    const inputSpanBlocks = document.querySelectorAll('.field');
                    inputSpanBlocks.forEach(block=> {
                        const input = block.querySelector('.custom-input');
                        input.parentElement.classList.add('error');
                    })
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }
    const feedbackModal = document.getElementById('feedback-modal-form');
    if(feedbackModal) {
        formValidate(
            feedbackModal, 
            schemaCallBack, 
            (body) => {
                sendData(body,'/api/feedback-request/').then(res=>{
                    window.location.href = '/success/';
                }).catch(error=>{
                    const inputSpanBlocks = document.querySelectorAll('.field');
                    inputSpanBlocks.forEach(block=> {
                        const input = block.querySelector('.custom-input');
                        input.parentElement.classList.add('error');
                    })
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }
    const service = document.getElementById('service-form');
    if(service) {
        const serviceModal = findParentWithClass(service, 'service-modal');
        formValidate(
            service, 
            schemaCallBack, 
            (body) => {
                if(serviceModal) {
                    const id = serviceModal.getAttribute('data-id');
                    body.service = id;
                }
                sendData(body,'/api/feedback-request/').then(res=>{
                    window.location.href = '/success/';
                }).catch(error=>{
                    const inputSpanBlocks = document.querySelectorAll('.field');
                    inputSpanBlocks.forEach(block=> {
                        const input = block.querySelector('.custom-input');
                        input.parentElement.classList.add('error');
                    })
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }
}

function formValidate(form, schema, callBack, formData) {
    form.onsubmit = (e) => {
        e.preventDefault();
    }
    const button = form.elements.button;

    const inputs = Object.keys(getBody(form))
        .map(el=> 
            document.querySelectorAll(`*[name=${el}]`)
        )
        .map(el=>Array.from(el).find(elem=>elem.closest(`#${form.id}`)));

    inputs.forEach((el)=>{
        el.oninput = (evt) => {
            inputs.forEach((e) => {
                e.classList.remove('error');
                // const field = findParentWithClass(e, 'field')
                // field.classList.remove('error')
            });

            const body = getBody(evt.target.form);
            validateParse({
                schema: schema,
                body,
            }).then(res => {
                console.log(res);
                if(button.classList.contains('button_accent')) button.classList.remove('button_accent_disabled');
                if(button.classList.contains('button_primary_dark')) button.classList.remove('button_primary_dark_disabled');
                button.onclick = () => {
                    const formDataBody = new FormData(form);
                    if(callBack) formData ? callBack(formDataBody) : callBack(body);
                }
            }).catch(error => {
                const parse = JSON.parse(`[${getTextInsideSquareBrackets(error.toString())}]`);
                const nameErrorInput = parse.map(el=>el.path[0]);
                const input = inputs.filter(el=>nameErrorInput.includes(el.name));
                if(button.classList.contains('button_accent')) button.classList.add('button_accent_disabled');
                if(button.classList.contains('button_primary_dark')) button.classList.add('button_primary_dark_disabled');
                input.forEach((el) => {
                    el.classList.add('error');
                    // const field = findParentWithClass(el, 'field');
                    // field.classList.add('error');
                });
                button.onclick = () => {};
            });
        }
    })
}



async function validateParse(validateInfo) {
    try {
        validateInfo.schema.parse(validateInfo.body);
        console.log("Validation successful");
        if(typeof validateInfo?.callback == 'function')validateInfo?.callback();
        return true;
    } 
    catch (error) {
        if (error instanceof z.ZodError) {
            // console.error("Validation failed:", error.errors);
            throw new Error(JSON.stringify(error.errors));
        } else {
            // console.error("Unknown error", error);
        }
    }
}

function getBody(form) {
    const formData = new FormData(form);
    const body = {};
    for (let [name, value] of formData.entries()) {
        body[name] = value;
    }
    return body;
}

function getQuery(query) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(query);
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { validation } from "./путь/к/файлу/validation.js";

// Активация: validation();