import { findParentWithClass, getData } from "./utils.js";

export function serviceModal() {
    const buttons = document.querySelectorAll('.open-modal[data-type-modal="service"]');
    if(buttons) {
        buttons.forEach(button=>{
            const item = findParentWithClass(button, 'services-section__item');
            if(item) {
                const modal = document.querySelector('.modal[data-type="service"]');

                const modalBannerTitle = modal.querySelector('.modal__content_service__body__banner__title');
                const modalImage = modal.querySelector('.modal__content_service__body__banner');
                const modalCost = modal.querySelector('.modal__content_service__body__banner').querySelector('span');
                button.addEventListener('click',()=>{
                    const itemId = item.getAttribute('data-id');
                    getData(`/api/service/${itemId}/`)
                        .then(res=>{
                            modal.setAttribute('data-id', itemId)
                            modalBannerTitle.innerText = res.name;
                            modalCost.innerText = `от ${res.price} ₽`;
                            modalImage.style.backgroundImage = `url(${res.photo})`
                        })
                });
            }
        });
    };
}