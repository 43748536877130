export function cookieAcceptation() {
    document.addEventListener('DOMContentLoaded', function () {
        const container = document.querySelector(".cookie-notification");
    
        if (!document.cookie.includes("warning=true")) {
            if(container) {
                container.style.display = "flex";
            }
        } else {
            container.parentElement.removeChild(container);
        }
    
        if (container) {
            const apply = document.querySelector(".cookie-notification__accept-btn");
            const deny = document.querySelectorAll(".cookie-notification__close-btn");
            if(apply) {
                apply.addEventListener("click", () => {
                    container.style.display = "none";
                    document.cookie = "warning=true; max-age=2592000; path=/";
                });
            }
            if(deny.length > 0) {
                deny.forEach((btn) => {
                    btn.addEventListener("click", () => {
                        container.style.display = "none";
                    })
                })
            }
        }
    });
}